import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import MultiForm from "./components/MultiForm";
// import Home from "./components/Home";
import LandingPage from "./components/Home";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  }
});

function App() {

  return (
    <ThemeProvider theme={theme}>

    <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<LandingPage/>}/>
      <Route path="/support"  element={<MultiForm/>}/>
    </Routes>
    </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;