import React from 'react';
import { AppBar, Toolbar, Typography, Fab, Card, CardContent, CardMedia, IconButton, useMediaQuery, Stack, Box, Container } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import backgroundImage from '../6060171.jpg'; // Placeholder for your background image
import sleco from '../sleco.jpg';
import solglobe from '../solglobe.jpg';
import solmain from '../SolanaSpin_Notion_01-1 (1).gif';
import descSol from '../What-is-Solana-800x533.png';
import { keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';


const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const handleNext = () => navigate('/support')

  // Keyframes for welcome message (fade-in)
  const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;

  // Keyframes for cards sliding in from the bottom
  const slideInFromBottom = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

  // Keyframes for rotating image
  const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

  // Card data array
  const cardData = [
    {
      title: '24/7 expert crypto support',
      image: solglobe,
      description: 'We are available anytime to provide expert guidance and assistance.',
    },
    {
      title: 'Secure Validation',
      image: descSol,
      description: 'Our platform ensures that your data is protected with top-notch encryption.',
    },
    {
      title: 'Fast Resolution',
      image: sleco,
      description: ' Resolve your issues within minutes. Our team is dedicated to providing swift suppor.',
    },
  ];

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CssBaseline />

      {/* AppBar */}
      <AppBar position="static" sx={{ backgroundColor: '#4A148C' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {isMobile ? 'SX' : 'Solana Network'}
          </Typography>

          <IconButton sx={{color: 'ffffff'}}>
            <SupportIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Animated Welcome Message */}
      <Box
        sx={{
          marginTop: theme.spacing(5),
          textAlign: 'center',
          animation: `${fadeIn} 2s ease-in-out`,
        }}
      >
        <Typography variant="h4" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
          Solana Network Support Page
        </Typography>

        {/* Rotating Image */}
        <Box
          component="img"
          src={solmain} // Replace with your icon image path
          alt="Rotating Icon"
          sx={{
            width: '200px', // Adjust size as needed
            height: '200px',
            borderRadius:'50%', // Continuous rotation
            margin: '16px auto', // Center the image
            display: 'block', // Ensure it's a block element
          }}
        />

        <Typography variant="h6" sx={{ color: '#ffffff', mt: 2 }}>
          Your trusted platform for all resolutions.
        </Typography>
      </Box>

      {/* Responsive Cards Section */}
      <Container sx={{ marginTop: theme.spacing(5), flexGrow: 1 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {cardData.map((card, index) => (
            <Card
              key={index}
              sx={{
                width: { xs: '100%', sm: '30%' },
                animation: `${slideInFromBottom} 1s ease-in-out`,
                animationDelay: `${index * 0.5}s`, // Staggered delay for each card
                animationFillMode: 'forwards',
                backgroundColor: '#4A148C',
                color: '#ffffff',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                borderRadius: '16px',
                opacity: 0, // Initial state for the animation
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={card.image}
                alt={card.title}
              />
              <CardContent>
                <Typography variant="h5">{card.title}</Typography>
                <Typography variant="body2">{card.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>

        {/* Social Media Links */}
        <Box sx={{ textAlign: 'center', mt: 5, mb: 8 }}>
          <Typography variant="h6" sx={{ color: '#ffffff' }}>
            Follow Solana on:
          </Typography>
          <Box sx={{ mt: 2 }}>
            <IconButton href="https://twitter.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
              <TwitterIcon />
            </IconButton>
            <IconButton href="https://instagram.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
              <InstagramIcon />
            </IconButton>
            <IconButton href="https://facebook.com/solana" target="_blank" sx={{ color: '#ffffff' }}>
              <FacebookIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Copyright Notice */}
        <Box sx={{ textAlign: 'center', mt: 4, mb: 8 }}>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            ©️ 2024 Solana Multichain. All rights reserved.
          </Typography>
        </Box>
      </Container>

      {/* Sticky "Get Support" Button */}
      <Fab
        variant="extended"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: '#6A1B9A',
          color: '#ffffff',
          '&:hover': { backgroundColor: '#4A148C' },
        }}
        onClick={handleNext}
      >
        <SupportIcon sx={{ mr: 1 }}  />
        Get Support
      </Fab>
    </Box>
  );
};

export default LandingPage;